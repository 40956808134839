<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" pesistent transition="scale-transition" max-width="1200px">
            <v-card>
                <ValidationObserver ref="obsEditEmpleado" v-slot="{ invalid, validated }" :immediate="true">
                    <v-form name="form" @submit.prevent="updateEmpleado">
                        <v-toolbar dark text color="cyan darken-4">
                            <v-btn icon dark @click="handleModal"><v-icon>fas fa-times-circle</v-icon></v-btn>
                            <v-toolbar-title>Editar datos del empleado</v-toolbar-title>
                            <v-spacer></v-spacer>

                            <v-toolbar-items>
                                <v-btn class="cyan darken-4" text type="submit"
                                :disabled="invalid || !validated">
                                    Guardar cambios <v-icon class="ma-2" dense large>fas fa-save</v-icon>
                                </v-btn>
                            </v-toolbar-items>
                        </v-toolbar>

                        <v-card-text>
                            <v-row>
                                <v-col cols="12" sm="6" md="4">
                                    <ValidationProvider name="nombre" rules="required" v-slot="{ errors, valid }" :immediate="true">
                                        <v-text-field label="Nombre del empleado*" prepend-icon="fas fa-user"
                                        v-model="empleado.nombre" :error-messages="errors" :success="valid" required :counter="100">
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>

                                <v-col cols="12" sm="6" md="4">
                                    <ValidationProvider name="apellidoPaterno" rules="required" v-slot="{ errors, valid }" :immediate="true">
                                        <v-text-field label="Apellido Paterno*" prepend-icon="fas fa-pen"
                                        required v-model="empleado.apellidoPaterno" :error-messages="errors" :success="valid">
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>

                                <v-col cols="12" sm="6" md="4">
                                    <ValidationProvider name="apellidoMaterno" rules="required" v-slot="{ errors, valid }" :immediate="true">
                                        <v-text-field label="Apellido Materno*" prepend-icon="fas fa-pen"
                                        required v-model="empleado.apellidoMaterno" :error-messages="errors" :success="valid">
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>

                                <v-col cols="12" sm="6" md="4">
                                    <ValidationProvider rules="required" v-slot="{ errors, valid }" :immediate="true">
                                        <v-autocomplete :items="empresas" item-text="empresa" item-value="id" 
                                        v-model="empleado.empresaId" label="Empresa*" required prepend-icon="fas fa-building"
                                        :error-messages="errors" :success="valid"></v-autocomplete>
                                    </ValidationProvider>
                                </v-col>

                                <v-col cols="12" sm="6" md="4">
                                    <ValidationProvider rules="required" v-slot="{ errors, valid }" :immediate="true">
                                        <v-autocomplete :items="sucursales" item-text="nombre" item-value="id"
                                        v-model="empleado.sucursalId" label="Sucursal*" required prepend-icon="fas fa-building"
                                        :error-messages="errors" :success="valid" placeholder="Seleccione primero una empresa"></v-autocomplete>
                                    </ValidationProvider>
                                </v-col>

                                <v-col cols="12" sm="6" md="4">
                                    <ValidationProvider rules="required" v-slot="{ errors, valid }" :immediate="true">
                                        <v-autocomplete :items="puestos" item-text="puesto" item-value="id" 
                                        v-model="empleado.puestoId" label="Puesto*" clearable prepend-icon="fas fa-briefcase"
                                        :error-messages="errors" :success="valid">
                                        ></v-autocomplete>
                                    </ValidationProvider>
                                </v-col>

                                <v-col cols="12" sm="6" md="4">
                                    <ValidationProvider rules="required" v-slot="{ errors, valid }" :immediate="true">
                                        <v-autocomplete :items="departamentos" item-text="departamento" item-value="id" 
                                            v-model="empleado.departamentoId" label="Departamento*" clearable prepend-icon="fas fa-briefcase"
                                            :error-messages="errors" :success="valid">
                                        </v-autocomplete>
                                    </ValidationProvider>
                                </v-col>

                                <v-col cols="12" sm="6" md="4">
                                    <ValidationProvider name="celular" rules="required|max:10" v-slot="{ errors, valid }" :immediate="true">
                                        <v-text-field label="Celular*" prepend-icon="fas fa-phone" type="number" :counter="10"
                                        required v-model="empleado.celular" :error-messages="errors" :success="valid">
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>

                                <v-col cols="12" sm="6" md="4">
                                    <ValidationProvider rules="required" name="idGenero" v-slot="{ errors, valid }" :immediate="true" >
                                        <v-autocomplete :items="generos" item-text="genero" item-value="id"
                                        v-model="empleado.generoId" label="Genero*" required clearable prepend-icon="fas fa-venus-mars"
                                        :error-messages="errors" :success="valid">
                                        </v-autocomplete>
                                    </ValidationProvider>
                                </v-col>

                                <v-col cols="12" sm="6" md="4">
                                    <ValidationProvider rules="required" name="direccion" v-slot="{ errors, valid}" :immediate="true">
                                        <v-text-field label="Dirección*" hint="Ubicacion del empleado"
                                        prepend-icon="fas fa-street-view" persistent-hint clearable v-model="empleado.direccion"
                                        :error-messages="errors" :success="valid">
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>

                                <v-col cols="12" sm="6" md="8">
                                    <ValidationProvider rules="required" name="categoriaSalarioId" v-slot="{ errors, valid }" :immediate="true">
                                        <v-autocomplete :items="tipoSalario" item-text="tipoSalario" item-value="id"
                                        v-model="empleado.categoriaSalarioId" label="Tipo Salario*" required persistent-hint
                                        prepend-icon="fas fa-hand-holding-usd" :error-messages="errors" :success="valid">
                                        </v-autocomplete>
                                    </ValidationProvider>
                                </v-col>

                                <v-col cols="12" md="6">
                                    <ValidationProvider rules="required|min_value:0" v-slot="{ errors, valid }" :immediate="true">
                                        <v-text-field label="Salario*" type="number" hint="Sueldo del empleado" persistent-hint
                                        v-model="empleado.salario" prepend-icon="fas fa-money-check-alt"
                                        :error-messages="errors" :success="valid">
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                            
                            <small>**Campos marcadas en rojo, son obligatorios**</small>
                        </v-card-text>
                    </v-form>
                </ValidationObserver>
                
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import SucursalDataService from '@/service/catalogo/sucursal/sucursalDataService';
import EmpleadoDataService from '@/service/empleado/empleadoDataService';

export default {
    name: 'EditarEmpleado',
    props: {
        dialog: { type: Boolean, required: true, default: false },
        handleModal: { type: Function },
        lstEmpleados: { type: Function },
        empleado: {},
        generos: { type: Array, required: true, default: [] },
        tipoSalario: { type: Array, required: true, default: [] },
        empresas: { type: Array, required: true, default: [] },
        puestos: { type: Array, required: true, default: [] },
        departamentos: { type: Array, required: true, default: [] },
    },
    created() {
        this.getSucursalesById(this.empleado.empresaId);
    },
    data() {
        return {
            sucursales: [],
            loading: false
        }
    },
    methods: {
        updateEmpleado() {
            this.$refs.obsEditEmpleado.validate().then((isValid) => {
                if ( isValid ) {
                    EmpleadoDataService.update(this.empleado).then(_ => {
                        this.$refs.obsEditEmpleado.reset();
                        this.handleModal();
                        this.lstEmpleados();
                        this.$swal('Excelente', 'Empleado modificada exitosamente', 'success');
                    }).catch((err) => {
                        this.$swal('Error', err.response.data, 'warning');
                    });
                }
            }).catch((err) => {
                this.$swal.fire({ icon: 'error', title: 'Error', html: 'Ocurrio un error durante la edición, error'+ err });
            });
        },
        async getSucursalesById(empresaId) {
            this.sucursales = await SucursalDataService.lstSucursalEmpresa(empresaId).then( resp => resp.data ) || [];
        }
    },
    watch: {
        'empleado.empresaId': function(empresaId) {
            if ( empresaId != null ) {
                this.empleado.sucursalId = null;
                this.getSucursalesById(empresaId);
            }
        }
    },
}
</script>