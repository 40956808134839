<template>
    <v-row align="center" justify="center">
        <v-col cols="12" sm="12" md="12">
            <v-card class="elevation-4">

                <v-card-title>
                    <v-toolbar flat>
                        <v-btn small dark color="cyan darken-4" @click="dialog = true">
                            <v-icon left>fas fa-plus-square</v-icon>Nuevo empleado
                        </v-btn>

                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>
                    </v-toolbar>

                    <v-col cols="12" md="8" sm="12" class="d-flex justify-end">
                        <v-text-field v-model="search" append-icon="fas fa-search" label="Buscar empleado" single-inline hide-details @keydown.enter="searchEmpleado"></v-text-field>

                        <v-btn icon class="mt-4 ml-2"><v-icon dense>fas fa-sync</v-icon></v-btn>
                        <Columns :headers.sync="headers"></Columns>
                    </v-col>
                </v-card-title>

                <v-data-table dense :headers="filteredHeaders" :items="empleados" class="elevation-1"
                :loading="loading" :options.sync="options"
                :footer-props="{ 'items-per-page-options': [ 25, 50, 100 ] }"
                :server-items-length="totalEmployee">
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-icon small class="mr-2" @click="editarEmpleado(item)" v-if="!item.eliminado">fas fa-edit</v-icon>
                        <v-icon small class="mr-2" @click="deleteEmpleado(item)" v-if="!item.eliminado">fas fa-trash-alt</v-icon>
                    </template>

                    <template v-slot:[`item.eliminado`]="{item}">
                        <v-chip :color="getColor(item.eliminado)" dark x-small v-text="item.eliminado == false ? 'Activo' : 'Inactivo'" ></v-chip>
                    </template>
                </v-data-table>

            </v-card>
        </v-col>
        
        <ModalNuevoEmpleado :dialog.sync="dialog" :lstEmpleados.sync="initTableEmployee"
        :generos.sync="generos" :tipoSalario.sync="tipoSalario" :puestos.sync="puestos"
        :departamentos.sync="departamentos" :empresas.sync="empresas"
        v-if="dialog" :handleModal="handleModal">
        </ModalNuevoEmpleado>

        <ModalEditarEmpleado :dialog.sync="dialogEdit" :empleado.sync="dataEmpleado"
        :generos.sync="generos" :tipoSalario.sync="tipoSalario" :puestos.sync="puestos"
        :departamentos.sync="departamentos" :empresas.sync="empresas"
        :lstEmpleados.sync="initTableEmployee" v-if="dialogEdit" :handleModal="handleEditModal">
        </ModalEditarEmpleado>
    </v-row>
</template>

<script>
import GeneroDataService from '@/service/catalogo/genero/generoDataService';
import PuestoDataService from '@/service/catalogo/puesto/puestoDataService';
import DepartamentoDataService from '@/service/catalogo/departamento/departamentoDataService';
import EmpresaDataService from '@/service/catalogo/empresa/empresaDataService';
import TipoSalarioDataService from '@/service/catalogo/tipoSalario/tipoSalarioDataService';

import EmpleadoDataService from '@/service/empleado/empleadoDataService';
import ModalNuevoEmpleado from './NuevoEmpleado.vue';
import ModalEditarEmpleado from './EditarEmpleado.vue';
import { getParamsPagination } from '@/utils/pagination';
import Columns from '@/components/shared/Columns.vue';

export default {
    name: 'ListaEmpleado',
    components: { ModalNuevoEmpleado, ModalEditarEmpleado, Columns },
    created() {
        this.initTableEmployee();
        this.getGeneros();
        this.getTipoSalario();
        this.getPuestos();
        this.getDepartamentos();
        this.getEmpresas();
    },
    data() {
        return {
            generos       : [],
            tipoSalario   : [],
            puestos       : [],
            departamentos : [],
            empresas      : [],

            empleados     : [],
            options       : {},
            totalEmployee : 0,
            loading       : false,
            search        : '',

            dialog        : false,
            dialogEdit    : false,

            dataEmpleado  : [],
            headers: [
                { text: 'Acciones', value: 'actions', sortable: false, groupable: false, show: true },
                { text : 'ID', sortable : false, value: 'id', align: ' d-none', visible: false, groupable: false, show: false },
                { text: 'Nombre Completo', value: 'nombreCompleto', align: 'start', sortable: false, groupable: false, show: true },
                { text: 'Empresa', value: 'empresa.razonSocial', sortable: false, groupable: false, show: true },
                { text: 'Sucursal', value: 'sucursal.nombre', sortable: false, groupable: false, show: true },
                { text: 'Departamento', value: 'departamento.departamento', sortable: false, groupable: false, show: true },
                { text: 'Puesto', value: 'puesto.puesto', sortable: false, groupable: false, show: true },
                { text: 'Celular', value: 'celular', sortable: false, groupable: false, show: true },
                { text: 'Dirección', value: 'direccion', sortable: false, groupable: false, show: true },
                { text: 'Estatus', value: 'eliminado', sortable: false, groupable: false, show: true },
            ]
        }
    },
    methods: {
        searchEmpleado() {
            this.options.page = 1;
            this.initTableEmployee(this.options);
        },
        initTableEmployee(options = {}) {
            this.loading = true;
            const params = getParamsPagination( { options, search: this.search } );

            EmpleadoDataService.table( params ).then( resp => {
                const { rows, totalElements } = resp.data;
                this.empleados = rows;
                this.totalEmployee = totalElements;
            }).catch((err) => {
                this.$swal('Error', err.response.data, 'error');
            }).finally(() => {
                this.loading = false;
            })
        },
        editarEmpleado( item ) {
            this.dataEmpleado = item;
            this.dialogEdit = true;
        },
        deleteEmpleado( item ) {
            const message = item.usuarioId 
                ? 'Al bloquear al empleado se desactivará su registro asociado (usuario).'
                : 'Al bloquear al empleado no se podrá revertir la acción.';

            this.$swal.fire({
                title: 'Estas seguro de bloquear el empleado?',
                text: message,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si, eliminar empleado!',
            }).then(result => {

                if (result.isConfirmed) {
                    EmpleadoDataService.delete( item.id ).then( resp => {
                        this.initTableEmployee();
                        this.$swal.fire({ position: 'top-end', title: 'Borrado', text: '¡Empleado eliminado exitosamente!', icon: 'success', showConfirmButton: false, timer: 1500 });
                    }).catch( err => {
                        this.$swal.fire({ position: 'top-end', title: 'Error', text: err.response, icon: 'error', showConfirmButton: false, timer: 1500 });
                    });
                }

            });
        },
        getColor( status ){ return ( status ) ? 'pink' : 'primary'; },
        handleModal() { this.dialog = !this.dialog; },
        handleEditModal() { this.dialogEdit = !this.dialogEdit; },
        async getGeneros() {
            this.generos = await GeneroDataService.all().then( g => g.data ) || [];
        },
        async getTipoSalario() {
            this.tipoSalario = await TipoSalarioDataService.lstTipoSalario().then( t => t.data ) || [];
        },
        async getPuestos() {
            this.puestos = await PuestoDataService.getallPuesto().then( r => r.data ) || [];
        },
        async getDepartamentos() {
            this.departamentos = await DepartamentoDataService.getallDepartamento().then( d => d.data ) || [];
        },
        async getEmpresas() {
            this.empresas = await EmpresaDataService.lstEmpresa().then( e => e.data ) || [];
        }
    },
    watch: {
        options: {
            handler( val, oldVal ) {
                if (val.page !== oldVal.page || val.itemsPerPage !== oldVal.itemsPerPage) {
                    this.initTableEmployee(val);
                }
            }, deep: true
        }
    },
    computed: {
        filteredHeaders() { return this.headers.filter( header => header.show ); }
    }
}
</script>