import { http, authHeader } from '@/http-commons';

class TipoSalarioDataService {
    create( salario ){
        return http.post('api/v1/tipoSalario/create', salario, { headers: authHeader() });
    }
    show( salaryId ){
        return http.get(`api/v1/tipoSalario/${salaryId}/edit`)
    }
    update(salario){
        return http.put(`api/v1/tipoSalario/update/${salario.id}`, salario, { headers: authHeader() });
    }
    delete( salaryId ){
        return http.delete(`api/v1/tipoSalario/delete/${salaryId}`, {headers: authHeader() });
    }
    lstTipoSalario(){
        return http.get('api/v1/tipoSalario/lstTipoSalario', {headers: authHeader() });
    }
    tableTipoSalario(params){
        return http.get('api/v1/tipoSalario/table',{params, headers: authHeader() });
    }
}
export default new TipoSalarioDataService();