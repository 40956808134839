import { http, authHeader } from '@/http-commons';

class GeneroDataService {

    create( genero ){
        return http.post('api/v1/genero/create', genero, { headers: authHeader() });
    }
    edit( id ){
        return http.get(`api/v1/genero/get/${id}`, { headers: authHeader() });
    }
    update(id, genero){
        return http.put(`api/v1/genero/update/${id}`, genero, { headers: authHeader() });
    }
    delete( id ){
        return http.delete(`api/v1/genero/delete/${id}`, { headers: authHeader() });
    }
    all(){
        return http.get('api/v1/genero/all', { headers : authHeader() });
    }
    table( params ){
        return http.get('api/v1/genero/allGeneros', {params, headers: authHeader() });
    }

}
export default new GeneroDataService();