<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent transition="scale-transition" max-width="1200px">
            <v-card class="elevation-1">

                <ValidationObserver ref="obsEmpleado" v-slot="{ invalid, validated}" slim>
                    <v-form name="form" @submit.prevent="saveEmpleado">
                        <v-toolbar dark text color="cyan darken-4">
                            <v-btn icon dark @click="handleModal">
                                <v-icon>fas fa-times-circle</v-icon>
                            </v-btn>

                            <v-toolbar-title>Registrar nuevo empleado</v-toolbar-title>
                            <v-spacer></v-spacer>

                            <v-toolbar-items>
                                <v-btn class="primary" type="submit"
                                :disabled="invalid || !validated">Guardar empleado
                                    <v-icon class="ma-2" dense large>fas fa-save</v-icon>
                                </v-btn>
                            </v-toolbar-items>
                        </v-toolbar>

                        <v-card-text>
                            <v-row>
                                <v-col cols="12" sm="6" md="4">
                                    <ValidationProvider rules="required" v-slot="{ errors, valid }">
                                        <v-text-field label="Nombre del empleado*" prepend-icon="fas fa-user"
                                        v-model="empleado.nombre" :error-messages="errors" :success="valid" required :counter="100">
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>

                                <v-col cols="12" sm="6" md="4">
                                    <ValidationProvider name="apellidoPaterno" rules="required" v-slot="{ errors, valid }">
                                        <v-text-field label="Apellido Paterno*" name="apellidoPaterno" prepend-icon="fas fa-pencil"
                                        required v-model="empleado.apellidoPaterno" :error-messages="errors" :success="valid">
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>

                                <v-col cols="12" sm="6" md="4">
                                    <ValidationProvider name="apellidoMaterno" rules="required" v-slot="{ errors, valid }">
                                        <v-text-field label="Apellido Materno*" name="apellidoMaterno" prepend-icon="fas fa-pencil"
                                        required v-model="empleado.apellidoMaterno" :error-messages="errors" :success="valid">
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>

                                <v-col cols="12" sm="6" md="4">
                                    <ValidationProvider rules="required" v-slot="{ errors, valid }">
                                        <v-autocomplete :items="empresas" item-text="empresa" item-value="id" 
                                        v-model="empleado.empresaId" label="Empresa*" required prepend-icon="fas fa-building"
                                        :error-messages="errors" :success="valid"></v-autocomplete>
                                    </ValidationProvider>
                                </v-col>

                                <v-col cols="12" sm="6" md="4">
                                    <ValidationProvider rules="required" v-slot="{ errors, valid }">
                                        <v-autocomplete :items="sucursales" item-text="nombre" item-value="id"
                                        v-model="empleado.sucursalId" label="Sucursal*" required prepend-icon="fas fa-building"
                                        :error-messages="errors" :success="valid" placeholder="Seleccione primero una empresa"></v-autocomplete>
                                    </ValidationProvider>
                                </v-col>

                                <v-col cols="12" sm="6" md="4">
                                    <ValidationProvider rules="required" v-slot="{ errors, valid }">
                                        <v-autocomplete :items="puestos" item-text="puesto" item-value="id" 
                                        v-model="empleado.puestoId" label="Puesto*" clearable prepend-icon="fas fa-briefcase"
                                        :error-messages="errors" :success="valid"
                                        ></v-autocomplete>
                                    </ValidationProvider>
                                </v-col>

                                <v-col cols="12" sm="6" md="4">
                                    <ValidationProvider rules="required" v-slot="{ errors, valid }">
                                        <v-autocomplete :items="departamentos" item-text="departamento" item-value="id" 
                                        v-model="empleado.departamentoId" label="Departamento*" clearable prepend-icon="fas fa-briefcase"
                                        :error-messages="errors" :success="valid"
                                        ></v-autocomplete>
                                    </ValidationProvider>
                                </v-col>

                                <v-col cols="12" sm="6" md="4">
                                    <ValidationProvider name="celular" rules="required|max:10" v-slot="{ errors, valid }">
                                        <v-text-field label="Celular*" name="celular" prepend-icon="fas fa-phone" type="number" :counter="10"
                                        required v-model="empleado.celular" :error-messages="errors" :success="valid">
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>

                                <v-col cols="12" sm="6" md="4">
                                    <ValidationProvider rules="required" v-slot="{ errors, valid }">
                                        <v-autocomplete :items="generos" item-text="genero" item-value="id"
                                        v-model="empleado.generoId" label="Genero*" required clearable prepend-icon="fas fa-venus-mars"
                                        :error-messages="errors" :success="valid">
                                        </v-autocomplete>
                                    </ValidationProvider>
                                </v-col>

                                <v-col cols="12" sm="6" md="8">
                                    <ValidationProvider rules="required" v-slot="{ errors, valid}">
                                        <v-text-field label="Dirección*" hint="Ubicacion del empleado"
                                        prepend-icon="fas fa-street-view" persistent-hint clearable v-model="empleado.direccion"
                                        :error-messages="errors" :success="valid">
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>

                                <v-col cols="12" sm="6" md="4">
                                    <ValidationProvider rules="required" v-slot="{ errors, valid }">
                                        <v-autocomplete :items="tipoSalario" item-text="tipoSalario" item-value="id"
                                        v-model="empleado.categoriaSalarioId" label="Tipo Salario*" required persistent-hint
                                        prepend-icon="fas fa-hand-holding-usd" :error-messages="errors" :success="valid">
                                        </v-autocomplete>
                                    </ValidationProvider>
                                </v-col>

                                <v-col cols="12" md="6">
                                    <ValidationProvider rules="required|min_value:0" v-slot="{ errors, valid }">
                                        <v-text-field label="Salario*" type="number" hint="Sueldo del empleado" persistent-hint
                                            v-model="empleado.salario" prepend-icon="fas fa-money-check-alt"
                                            :error-messages="errors" :success="valid">
                                        </v-text-field>
                                    </ValidationProvider>
                                        
                                </v-col>
                            </v-row>

                            <small>**Campos marcadas en rojo, son obligatorios**</small>
                        </v-card-text>
                    </v-form>
                </ValidationObserver>

            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import SucursalDataService from '@/service/catalogo/sucursal/sucursalDataService';
import EmpleadoDataService from '@/service/empleado/empleadoDataService';

export default {
    name: 'NuevoEmpleado',
    props: {
        dialog: { default: false },
        handleModal: { type: Function },
        lstEmpleados: { type: Function },
        generos: { type: Array, required: true, default: [] },
        tipoSalario: { type: Array, required: true, default: [] },
        empresas: { type: Array, required: true, default: [] },
        puestos: { type: Array, required: true, default: [] },
        departamentos: { type: Array, required: true, default: [] },
    },
    data() {
        return {
            sucursales: [],
            empleado: {
                nombre: '',
                apellidoPaterno: '',
                apellidoMaterno: '',
                direccion: '',
                celular: '',
                empresaId: null,
                sucursalId: null,
                departamentoId: null,
                puestoId: null,
                generoId: null,
                cuota: 0,
                categoriaSalarioId: null,
                salario: 0
            },
            loading: false
        }
    },
    methods: {
        saveEmpleado() {
            this.$refs.obsEmpleado.validate().then( isValid => {
                if ( isValid ) {
                    EmpleadoDataService.create(this.empleado).then( _ => {
                        this.$refs.obsEmpleado.reset();
                        this.handleModal();
                        this.lstEmpleados();
                        this.$swal('Excelente', 'Empleado registrada exitosamente', 'success');
                    }).catch(err => {
                        this.$swal.fire({ position: 'top-end', icon: 'error', html: err.response.data, title: 'Error al registrar empleado', showConfirmButton: false, timer: 3000 });
                    });
                }
            }).catch(err => {
                this.$swal.fire({ position: 'top-end', icon: 'error', text: err, title: 'Error al registrar empleado', showConfirmButton: false, timer: 3000 });
            });
        },
        async getSucursalesById(empresaId) {
            this.sucursales = await SucursalDataService.lstSucursalEmpresa(empresaId).then( resp => resp.data ) || [];
        }
    },
    watch: {
        'empleado.empresaId': function(empresaId) {
            if ( empresaId != null ) {
                this.empleado.sucursalId = null;
                this.getSucursalesById(empresaId);
            }
        }
    }
}
</script>